<template>
  <div>
    <div style="height: 0; opacity: 0; overflow: hidden; min-width:790px; width:790px; max-width:unset;">
      <b-card
        id="pdfGenerateDiv"
        ref="pdfGenerateDivRef"
        no-body
        class="invoice-preview-card"
      >
        <div
          v-for="pg in pdfTotalPage"
          :key="pg"
          class="card__inner"
          style="height: 1080px;"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row invoice-spacing mt-0">
              <div>
                <div class="logo-wrapper">
                  <img
                    style="width:130px;"
                    src="/nirvana-memorial-garden-logo.png"
                    alt="Logo"
                  >
                </div>
              </div>
              <div class="mt-md-0 mt-2 text-right">
                <h4
                  class="invoice-title"
                  style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                >
                  {{ vendor.companyName }}
                </h4>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Prepared By:</span> {{ vendor.createdBy ? vendor.createdBy.name : '' }}, {{ vendor.createdBy ? vendor.createdBy.position : '' }}, {{ vendor.createdBy ? (vendor.createdBy.department.length ? vendor.createdBy.department[0].name : '') : '' }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(vendor.createdAt) }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr
            class="invoice-spacing"
            style="margin:15px 0!important"
          >

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            v-if="pg == 1"
            class="invoice-padding pt-0 main__body"
          >
            <b-row class="invoice-spacing">
              <b-col
                cols="md-12"
                class="p-0"
              >
                <h1
                  class="text-bold-black"
                  style="font-size:14px; line-height:18px; font-weight:900; color:#000000; margin:0 0 15px"
                >
                  New Vendor Registration Form
                </h1>
                <h6
                  style="font-size:14px; line-height:17px; color:#000000; margin:0 0 8px; font-weight:500;"
                >
                  Section 1: Company General Information
                </h6>
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th width="230px">
                        Registration No.
                      </b-th>
                      <b-td>{{ vendor.companyRegistration }}</b-td>
                      <b-th width="230px">
                        Tel
                      </b-th>
                      <b-td>{{ vendor.companyContact }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        GST Registration No.
                      </b-th>
                      <b-td>{{ vendor.companyGSTRegistration }}</b-td>
                      <b-th width="230px">
                        Fax
                      </b-th>
                      <b-td>{{ vendor.companyFax }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        NRIC No. (For Individual)
                      </b-th>
                      <b-td>{{ vendor.individualNRIC }}</b-td>
                      <b-th width="230px">
                        Registered Address
                      </b-th>
                      <b-td>{{ vendor.companyAddress }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <hr
              class="invoice-spacing"
              style="margin:15px 0!important"
            >
            <b-row>
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6
                  style="font-size:14px; line-height:17px; color:#000000; margin:0 0 8px; font-weight:500;"
                >
                  Section 2: Sales Person Information
                </h6>
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th width="230px">
                        Name
                      </b-th>
                      <b-td>{{ vendor.salesPersonName }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Contact No.
                      </b-th>
                      <b-td>{{ vendor.salesPersonContact }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Email Address
                      </b-th>
                      <b-td>{{ vendor.salesPersonEmail }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Email Address 2
                      </b-th>
                      <b-td>{{ vendor.salesPersonEmail2 }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Any Related Family Parties Working In NV Group?
                      </b-th>
                      <b-td>
                        <span class="text-capitalize">{{ vendor.relativeInNirvana }}</span>
                        <br>
                        <span v-if="vendor.relativeInNirvana == 'yes'"> {{ vendor.relativeDetail }}</span>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <hr
              class="invoice-spacing"
              style="margin:15px 0!important"
            >
            <b-row>
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6 style="font-size:14px; line-height:17px; color:#000000; margin:0 0 8px; font-weight:500;">
                  Section 3: Product / Service Provided and Payment Terms
                </h6>
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th width="230px">
                        Type of Product/Service Provided
                      </b-th>
                      <b-td>{{ vendor.providedServiceType }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Payment Terms
                      </b-th>
                      <b-td>{{ resolvePaymentTerms(vendor.paymentTerms) }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>

            <hr
              class="invoice-spacing"
              style="margin:15px 0!important"
            >
            <b-row>
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6 style="font-size:14px; line-height:17px; color:#000000; margin:0 0 8px; font-weight:500;">
                  Section 4: Banking Information
                </h6>
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th width="230px">
                        Account Payee Name
                      </b-th>
                      <b-td>{{ vendor.accountPayeeName }}</b-td>
                      <b-th width="230px">
                        Bank Account No.
                      </b-th>
                      <b-td>{{ vendor.bankAccountNo }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Bank Name
                      </b-th>
                      <b-td>{{ vendor.bankName }}</b-td>
                      <b-th width="230px">
                        Bank Address
                      </b-th>
                      <b-td>{{ vendor.bankAddress }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Bank Branch
                      </b-th>
                      <b-td>{{ vendor.bankBranch }}</b-td>
                      <b-th width="230px">
                        Email Address
                      </b-th>
                      <b-td>{{ vendor.bankEmail }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th width="230px">
                        Swift Code (For Oversea)
                      </b-th>
                      <b-td>{{ vendor.swiftCode }}</b-td>
                      <b-th width="230px">
                        Paynow Details
                      </b-th>
                      <b-td>{{ vendor.paynowNo ? vendor.paynowNo : '-' }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <hr
              class="invoice-spacing"
              style="margin:15px 0!important"
            >
            <b-row>
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6 style="font-size:14px; line-height:17px; color:#000000; margin:0 0 8px; font-weight:500;">
                  Section 5: Certification Obtained
                </h6>
              </b-col>
              <b-col
                v-for="(certificate, key) in vendor.certificates"
                :key="key"
                class="content-header-left"
                cols="12"
                md="4"
                style="margin-bottom:7px"
              >
                <p
                  style="font-size:11px; line-height:16px; margin:0; color:#333333;"
                >
                  <span v-if="certificate == 'Others'">
                    {{ vendor.otherCertificates }}
                  </span>
                  <span v-else>
                    {{ certificate }}
                  </span>
                </p>
              </b-col>
            </b-row>
            <hr
              class="invoice-spacing"
              style="margin:8px 0 7px!important"
            >
            <p
              style="font-size:10px; line-height:14px; margin:0 0 15px; color:#000000;"
            >
              Section 1–5 to be completed by vendor and kindly attach with necessary supporting documents for submission.
            </p>
            <p
              style="font-size:10px; line-height:14px; margin:0 0 0px; color:#000000;"
            >
              I hereby affirm that all information supplied is true and accurate to the best of my knowledge, and I understand that this information will be taken into consideration during the vendor evaluation. In the condition of any changes impacting the information provided above, a notice shall be provided to the company within 14 days of the said change.
            </p>
          </b-card-body>
          <div
            v-if="pg == pdfTotalPage"
            style="position: relative; bottom: 0; width: 100%;"
          >
            <div :style="{ height: gapHeight+'px' }" />
            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0 footer__area">
              <b-row>

                <b-col
                  v-for="(signatory, index) of vendor.signatories"
                  :key="index"
                  cols="3"
                  md="3"
                  class="mt-md-1 mt-1"
                  order="2"
                  order-md="1"
                >
                  <b-card-text class="mb-0">
                    <span class="font-weight-bold">{{ index + 1 }}. {{ signatory.type }}</span>
                    <div class="sign-box">
                      <div v-if="signatory.signed == true">
                        <!-- <div v-if="signatory.user && signatory.user.signature">
                          <b-img
                            class="mb-1 mb-sm-0 user__avatar"
                            style="height: 40px;width: 100%;object-fit: contain;display: block;"
                            :src="signatory.user.signature"
                          />
                        </div> -->
                        <div v-if="signatory.user && signatory.user.name">
                          <span>{{ signatory.user.name }}</span>
                        </div>
                      </div>
                      <div v-else>
                        <p>Pending Signature</p>
                      </div>
                    </div>
                    <p class="ml-75 semi-bold">
                      {{ signatory.user ? signatory.user.name : '' }}
                    </p>
                    <p class="ml-75">
                      {{ signatory.user ? (signatory.user.department.length ? signatory.user.department[0].name : '') : '' }}
                    </p>
                    <p class="ml-75">
                      {{ signatory.signed == true ? dateFormatWithTime(signatory.date) : '-' }}
                    </p>
                  </b-card-text>
                </b-col>
              </b-row>
            </b-card-body>
          </div>
        </div>
      </b-card>
      <b-card
        no-body
        class="invoice-preview-card"
      >
        <div
          class="card__inner"
          style="height: 1120px;"
        >
          <!-- Header -->
          <b-card-body
            id="main-header"
            class="invoice-padding pb-0"
          >
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="logo-wrapper">
                <img
                  style="width:130px;"
                  src="/nirvana-memorial-garden-logo.png"
                  alt="Logo"
                >
              </div>
              <div class="mt-md-0 mt-2 text-right">
                <h4
                  class="invoice-title"
                  style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                >
                  {{ vendor.companyName }}
                </h4>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Prepared By:</span> {{ vendor.createdBy ? vendor.createdBy.name : '' }}, {{ vendor.createdBy ? vendor.createdBy.position : '' }}, {{ vendor.createdBy ? (vendor.createdBy.department.length ? vendor.createdBy.department[0].name : '') : '' }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p
                    class="invoice-date"
                    style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                  >
                    <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(new Date()) }}
                  </p>
                </div>
              </div>
            </div>
            <!-- Spacer -->
            <hr
              class="invoice-spacing"
              style="margin-top:25px!important"
            >
          </b-card-body>

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            id="content-div"
            class="invoice-padding pt-2 main__body po__create"
          >
            <b-row>
              <b-col cols="md-12">
                <h1 class="text-bold-black pl-1">
                  New Vendor Registration Form
                </h1>
              </b-col>
            </b-row>
            <b-row id="section1">
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6 class="ml-2 mt-2">
                  Section 1: Company General Information
                </h6>
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th>
                        Registration No.
                      </b-th>
                      <b-td>{{ vendor.companyRegistration }}</b-td>
                      <b-th>
                        Tel
                      </b-th>
                      <b-td>{{ vendor.companyContact }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        GST Registration No.
                      </b-th>
                      <b-td>{{ vendor.companyGSTRegistration }}</b-td>
                      <b-th>
                        Fax
                      </b-th>
                      <b-td>{{ vendor.companyFax }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        NRIC No. (For Individual)
                      </b-th>
                      <b-td>{{ vendor.individualNRIC }}</b-td>
                      <b-th>
                        Registered Address
                      </b-th>
                      <b-td>{{ vendor.companyAddress }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <hr class="invoice-spacing">
            <b-row id="section2">
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6 class="ml-2 mt-2">
                  Section 2: Sales Person Information
                </h6>
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th>
                        Name
                      </b-th>
                      <b-td>{{ vendor.salesPersonName }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Contact No.
                      </b-th>
                      <b-td>{{ vendor.salesPersonContact }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Email Address
                      </b-th>
                      <b-td>{{ vendor.salesPersonEmail }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Email Address 2
                      </b-th>
                      <b-td>{{ vendor.salesPersonEmail2 }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Any Related Family Parties Working In NV Group?
                      </b-th>
                      <b-td>
                        <span class="text-capitalize">{{ vendor.relativeInNirvana }}</span>
                        <br>
                        <span v-if="vendor.relativeInNirvana == 'yes'"> {{ vendor.relativeDetail }}</span>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <hr class="invoice-spacing">
            <b-row id="section3">
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6 class="ml-2 mt-2">
                  Section 3: Product / Service Provided and Payment Terms
                </h6>
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th>
                        Type of Product/Service Provided
                      </b-th>
                      <b-td>{{ vendor.providedServiceType }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Payment Terms
                      </b-th>
                      <b-td>{{ resolvePaymentTerms(vendor.paymentTerms) }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>

            <hr class="invoice-spacing">
            <b-row id="section4">
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6 class="ml-2 mt-2">
                  Section 4: Banking Information
                </h6>
                <b-table-simple
                  borderless
                  responsive
                >
                  <b-tbody>
                    <b-tr>
                      <b-th>
                        Account Payee Name
                      </b-th>
                      <b-td>{{ vendor.accountPayeeName }}</b-td>
                      <b-th>
                        Bank Account No.
                      </b-th>
                      <b-td>{{ vendor.bankAccountNo }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Bank Name
                      </b-th>
                      <b-td>{{ vendor.bankName }}</b-td>
                      <b-th>
                        Bank Address
                      </b-th>
                      <b-td>{{ vendor.bankAddress }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Bank Branch
                      </b-th>
                      <b-td>{{ vendor.bankBranch }}</b-td>
                      <b-th>
                        Account Currency
                      </b-th>
                      <b-td>{{ vendor.accountCurrency }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>
                        Swift Code (For Oversea)
                      </b-th>
                      <b-td>{{ vendor.swiftCode }}</b-td>
                      <b-th>
                        Email Address
                      </b-th>
                      <b-td>{{ vendor.bankEmail }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <hr class="invoice-spacing">
            <b-row id="section5">
              <b-col
                cols="md-12"
                xl="12"
              >
                <h6 class="ml-2 mt-2">
                  Section 5: Certification Obtained
                </h6>
              </b-col>
              <b-col
                v-for="(certificate, key) in vendor.certificates"
                :key="key"
                class="content-header-left mb-2"
                cols="12"
                md="4"
              >
                <div
                  class="attachment-item"
                >
                  <span v-if="certificate == 'Others'">
                    {{ vendor.otherCertificates }}
                  </span>
                  <span v-else>
                    {{ certificate }}
                  </span>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Total -->
          <b-card-body
            id="footer-div"
            class="invoice-padding pb-0 footer__area"
          >
            <!-- Spacer -->
            <hr class="invoice-spacing">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                v-for="(signatory, index) of vendor.signatories"
                :key="index"
                cols="3"
                md="3"
                class="mt-md-1 mt-1"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">{{ index + 1 }}. {{ signatory.type }}</span>
                  <div class="sign-box">
                    <div v-if="signatory.signed == true">
                      <!-- <div v-if="signatory.user && signatory.user.signature">
                        <b-img
                          class="mb-1 mb-sm-0 user__avatar"
                          height="50"
                          :src="signatory.user.signature"
                        />
                      </div> -->
                      <div v-if="signatory.user && signatory.user.name">
                        <span>{{ signatory.user.name }}</span>
                      </div>
                    </div>
                    <div v-else>
                      <p>Pending Signature</p>
                    </div>
                  </div>
                  <p class="ml-75 semi-bold">
                    {{ signatory.user ? signatory.user.name : '' }}
                  </p>
                  <p class="ml-75">
                    {{ signatory.user ? (signatory.user.department.length ? signatory.user.department[0].name : '') : '' }}
                  </p>
                  <p class="ml-75">
                    {{ signatory.signed == true ? dateFormatWithTime(signatory.date) : '-' }}
                  </p>
                </b-card-text>
              </b-col>
            </b-row>
          </b-card-body>
        </div>
      </b-card>
    </div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="create-edit-form">
          <b-col cols="12">
            <h1
              class="custom-header-title"
            >
              Review Vendor
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <section class="invoice-preview-wrapper">
      <b-row class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <div>
            <b-card
              class="mb-0"
            >
              <b-row class="invoice-preview">
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-left"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="link"
                    :disabled="currentFileIndex == 1"
                    @click="changeFileIndex(currentFileIndex - 1)"
                  >
                    <feather-icon
                      icon="ChevronLeftIcon"
                      class="pt-25"
                      size="18"
                    />
                    <span class="align-middle">Prev</span>
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-center"
                >
                  <b-dropdown
                    id="dropdown-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    split
                  >
                    <template #button-content>
                      <feather-icon
                        icon="FileIcon"
                        size="18"
                        class="align-middle mr-1"
                      />
                      <span class="mr-1">{{ currentFileIndex.toString().padStart(2, "0") }} / {{ vendor.attachments ? ((vendor.attachments.length + 1).toString().padStart(2, "0")) : '01' }}: {{ selectedFileName }}</span>
                    </template>
                    <b-dropdown-item @click="changeFileIndex(1)">
                      {{ vendor.stringID }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-for="(file, key) in vendor.attachments"
                      :key="key"
                      @click="changeFileIndex(key + 2)"
                    >
                      {{ file ? file.name : '' }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <p
                    v-show="currentFileIndex == 1"
                    class="mt-1"
                  >
                    Purchase Order (PO)
                  </p>
                  <p
                    v-show="currentFileIndex != 1"
                    class="mt-1"
                  >
                    {{ vendor.attachments ? (vendor.attachments[currentFileIndex - 2] ? vendor.attachments[currentFileIndex - 2].description : '') : '' }}
                  </p>
                </b-col>
                <b-col
                  cols="12"
                  xl="4"
                  md="4"
                  class="text-right"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="link"
                    :disabled="currentFileIndex == (vendor.attachments ? (vendor.attachments.length + 1) : 1)"
                    @click="changeFileIndex(currentFileIndex + 1)"
                  >
                    <span class="align-middle">Next</span>
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="pt-25"
                      size="18"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </div>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <div v-show="showAttachment">
              <iframe
                v-show="showPDF"
                id="attachmentpdf"
                width="100%"
                height="1145"
              />
              <b-img
                v-show="!showPDF"
                :src="imageSrc"
                fluid-grow
                alt="Fluid-grow image"
              />
            </div>
            <div v-show="!showAttachment">
              <iframe
                id="docpdf"
                width="100%"
                height="1145"
              />
            </div>
          </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
        >
          <b-card>
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                <span class="step-class">{{ approverHeader }}</span>
                {{ approverTitle }}
              </h3>
            </template>
            <b-card-text
              v-if="finalSignatory"
              class="mb-0"
            >
              <span class="text-bold">You are now reviewing this vendor as the signatory for "{{ approverType }}".</span>
              <br>
              <span>
                If the information of this vendor is accurate, e-sign this form to approve this vendor.
              </span>
            </b-card-text>
            <b-card-text
              v-else
              class="mb-0"
            >
              <span class="text-bold">You are now reviewing this vendor as the signatory for "{{ approverType }}".</span>
              <br>
              <span>If the information of this vendor is accurate, e-sign this form and it will be sent to the next assigned signatory ({{ nextApproverName }}) for signing.</span>
            </b-card-text>

            <b-form-checkbox
              v-model="secondAgree"
              value="accepted"
              unchecked-value=""
              class="custom-control-primary"
            >
              I confirm that all information is accurate on this form.
            </b-form-checkbox>

            <!-- Button: Send Invoice -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
              :disabled="secondAgree == '' || !canVerify"
              @click="approveVendor()"
            >
              <feather-icon
                icon="FeatherIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Sign and {{ approveButtonText }} Vendor</span>
            </b-button>

            <!-- Button: DOwnload -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="flat-primary"
              class="mb-75"
              block
              :to="{ name: 'purchasing-vendors-approve', params: { id: $route.params.id } }"
            >
              Back to Vendor Detail
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BImg, BCardText, VBTooltip, BTableSimple, BTbody,
  BTr, BTh, BTd, BFormCheckbox, BCardBody, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { required } from '@validations'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import html2pdf from 'html2pdf.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BImg,
    BCardText,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BFormCheckbox,
    BCardBody,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  // props: {
  //   vendor: {
  //     type: Object,
  //     required: true,
  //   },
  //   vendor: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      vendor: {},
      tabType: '',
      vendorSignatories: [],
      vendorItems: [],
      vendorID: '',
      stringID: '',
      comment: '',
      extraComment: '',
      approverType: '',
      approverHeader: '',
      approverTitle: '',
      nextApproverName: '',
      commentValidation: false,
      commentError: '',
      status: 'confirmed',
      approveButtonText: '',
      statusValidation: false,
      selectedFileName: '',
      currentFileIndex: 1,
      chunkItemRequests: [],
      chunksOfItemRequests: [],
      prComments: [],
      pdfTotalPage: 0,
      elHeight: 0,
      gapHeight: 0,
      secondAgree: '',
      pdfDataJS: '',
      imageSrc: '',
      showPDF: true,
      showAttachment: false,
      showFirst: true,
      finalSignatory: false,
      canVerify: false,
      signIndex: 0,
      userID: '',
      statusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Draft', code: 'draft' },
        { title: 'Pending Edit', code: 'pending edit' },
        { title: 'Pending Approval', code: 'pending approval' },
        { title: 'Verifying', code: 'verifying' },
        { title: 'Verified', code: 'verified' },
        { title: 'Cancelled', code: 'cancelled' },
      ],
      description: '',
      attachmentKey: '',
      required,
      lqas: [
        '1. Prepared by',
        '2. Requested by',
        '3. Verified by',
        '4. Recommended by',
        '5. Agreed by',
        '6. Certified by',
        '7. Confirmed by',
        '8. Supported by',
        '9. Validated by',
        '10. Approved by',
      ],
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  mounted() {
    this.$http.get(`purchase/vendors/${this.$route.params.id}/detail/approve`)
      .then(response => {
        this.vendor = response.data.data || {}
        this.selectedFileName = this.vendor.stringID

        const userFound = this.vendor.signatories.findIndex(o => o.user._id === this.user._id && o.signed === false)
        if (userFound !== -1) {
          if (userFound !== 0) {
            this.canVerify = true
            this.signIndex = userFound
            this.approveButtonText = 'Approve'

            this.userID = this.vendor.signatories[userFound].user._id
            if (userFound === (this.vendor.signatories.length - 1)) {
              this.finalSignatory = true
              this.approverType = this.vendor.signatories[userFound].type
              this.approverHeader = 'Signature Required'
              this.approverTitle = 'Approve This Vendor?'
              this.nextApproverName = ''
            } else {
              this.approverType = this.vendor.signatories[userFound].type
              this.approverHeader = 'Signature Required'
              this.approverTitle = 'Approve This Vendor?'
              this.nextApproverName = this.vendor.signatories[userFound + 1].user.name
            }
          } else {
            this.canVerify = true
            this.signIndex = userFound

            this.userID = this.vendor.signatories[userFound].user._id
            this.approverType = this.vendor.signatories[userFound].type
            this.approverHeader = 'Signature Required'
            this.approverTitle = 'Submit For Approval?'
            this.approveButtonText = 'Submit'
            this.nextApproverName = this.vendor.signatories[userFound + 1].user.name
          }
        }
        this.enablePreview()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    resolvePaymentTerms(val) {
      let text = ''
      if (val === 'Others') {
        text = this.vendor.paymentTermsDescription
      } else {
        text = `${val}`
      }

      return text
    },
    changeFileIndex(key) {
      this.currentFileIndex = key
      if (key === 1) {
        this.selectedFileName = this.vendor.stringID
        // eslint-disable-next-line func-names
        // pdfViewerFrame.onload = () => {
        //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.pdfDataJS)
        // }
        // this.showFirst = false
        // const pdfViewerFrame = document.getElementById('docpdf2')
        // pdfViewerFrame.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.pdfDataJS}`)
        this.showAttachment = false
      } else {
        this.selectedFileName = this.vendor.attachments[key - 2].name
        if (this.vendor.attachments[key - 2].type.includes('pdf')) {
          // eslint-disable-next-line func-names
          // pdfViewerFrame.onload = () => {
          //   pdfViewerFrame.contentWindow.PDFViewerApplication.open(this.vendor.attachments[key - 2].data)
          // }
          const pdfViewerFrame2 = document.getElementById('attachmentpdf')
          pdfViewerFrame2.setAttribute('src', `/lib/pdfjs/web/viewer.html?file=${this.vendor.attachments[key - 2].data}`)
          this.showPDF = true
        } else {
          this.imageSrc = this.vendor.attachments[key - 2].data
          this.showPDF = false
        }
        this.showAttachment = true
      }
    },
    resolveItemCounter(page, index) {
      let count = 0
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < (page - 1); i++) {
        count += this.chunksOfItemRequests[i].length
      }

      count += index + 1

      return count
    },
    base64ToUint8Array(base64) {
      const raw = atob(base64)
      const uint8Array = new Uint8Array(raw.length)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i)
      }
      return uint8Array
    },
    async enablePreview() {
      store.commit('appConfig/UPDATE_LOADER', true)
      const vm = this
      await this.calculateTotalPage()

      // start of pdf using html2PDF package
      const element = document.getElementById('pdfGenerateDiv')
      const opt = {
        margin: 1,
        filename: 'myfile.pdf',
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'], after: '.page-section' },
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          scale: 2,
          dpi: 192,
          // windowWidth: 1024,
          letterRendering: true,
        },
        jsPDF: { unit: 'mm', format: [297, 210], orientation: 'p' },
      }

      html2pdf().set(opt).from(element).toPdf()
        .get('pdf')
        .then(pdf => {
          vm.pdfData = pdf.output('datauristring')
          const base64Raw = vm.pdfData.split(',')[1]
          const pdfDataJS2 = vm.base64ToUint8Array(base64Raw)
          this.pdfDataJS = pdfDataJS2
          const pdfViewerFrame = document.getElementById('docpdf')
          // eslint-disable-next-line func-names
          pdfViewerFrame.onload = () => {
            pdfViewerFrame.contentWindow.PDFViewerApplication.open(pdfDataJS2)
          }
          pdfViewerFrame.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          store.commit('appConfig/UPDATE_LOADER', false)
        })
      // end of pdf using html2PDF package
      // this.pdfData = data
      // this.POPreview = true
    },
    async calculateTotalPage() {
      await this.$nextTick()
      this.pdfTotalPage = 1
      const logoHeight = document.getElementById('main-header').offsetHeight
      const footerHeight = document.getElementById('footer-div').offsetHeight
      const contentHeight = document.getElementById('content-div').offsetHeight
      let remainingHeight = 0
      const dHeight = 1120
      remainingHeight = dHeight - (logoHeight + footerHeight + contentHeight)
      if (remainingHeight > 0) {
        this.gapHeight = remainingHeight
      }
      if (contentHeight >= (dHeight - (logoHeight + footerHeight))) {
        this.pdfTotalPage += 1
        this.gapHeight = dHeight - (logoHeight + footerHeight)
      }
    },
    addComment() {
      this.$http
        .post('purchase/purchase-request/save-comment', { comment: this.extraComment, stage: 'in-order', vendor: this.$route.params.id })
        .then(response => {
          this.extraComment = ''
          this.prComments = response.data.comments
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.vendor.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    getSubTotal(key) {
      const vendor = this.vendor.comparedVendors[key]
      let total = 0
      if (vendor.code === 'GST Inclusive') {
        this.poItems.forEach(item => {
          total += item.unitCost[key] ? item.quantity * item.unitCost[key] : 0
        })
        total -= this.getGST(key)
      } else {
        this.poItems.forEach(item => {
          total += item.unitCost[key] ? item.quantity * item.unitCost[key] : 0
        })
      }

      const discount = this.vendor.discountList[key] ? this.vendor.discountList[key] : 0
      const dCost = this.vendor.deliveryCost[key] ? this.vendor.deliveryCost[key] : 0

      total = total - discount + parseFloat(dCost)
      return Number.isNaN(total) ? '0.00' : total.toFixed(2)
    },
    getGST(key) {
      const vendor = this.vendor.comparedVendors[key]
      if (vendor.code === 'No GST') {
        return '-'
      }

      if (vendor.code === 'GST Inclusive') {
        let gstableAmount = 0
        this.poItems.forEach(item => {
          gstableAmount += (item.gst[key] && item.gst[key] === 'yes') ? item.quantity * (item.unitCost[key] ? item.unitCost[key] : 0) : 0
        })
        // eslint-disable-next-line no-mixed-operators
        const gstAmount = ((gstableAmount / (100 + parseFloat(this.vendor.gstPercentage))) * parseFloat(this.vendor.gstPercentage))
        return Number.isNaN(gstAmount) ? '0.00' : gstAmount.toFixed(2)
      }

      let total = 0

      this.poItems.forEach(item => {
        total += (item.gst[key] && item.gst[key] === 'yes') ? item.quantity * (item.unitCost[key] ? item.unitCost[key] : 0) : 0
      })

      // eslint-disable-next-line operator-assignment
      total = ((this.vendor.gstPercentage / 100) * total)

      return Number.isNaN(total) ? '0.00' : total.toFixed(2)
    },
    getGrandTotal(key) {
      return ((this.getGST(key) === '-' ? 0 : parseFloat(this.getGST(key))) + parseFloat(this.getSubTotal(key))).toFixed(2)
    },
    removeAttachment(url) {
      this.$http
        .post('purchase/purchase-request/remove-attachment', { url, vendor: this.$route.params.id })
        .then(response => {
          this.$emit('update:vendor', response.data.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveDescription() {
      const url = this.vendor.attachments[this.attachmentKey].data
      this.$http
        .post('purchase/purchase-request/save-description', { url, description: this.description, vendor: this.$route.params.id })
        .then(response => {
          this.$emit('update:vendor', response.data.data)
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    warningBeforeEdit() {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Editing the form now will <strong>reset the approval process</strong> and require all signatories to confirm and sign once again.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Edit this PO',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'purchasing-purchase-requests-edit', params: { id: this.$route.params.id } })
          }
        })
    },
    submitForApproval() {
      this.$swal({
        title: 'Submit this PO?',
        html: `You are proceeding to sign this PO. The PO will then be sent to ${this.vendor.signatories[1] ? this.vendor.signatories[1].user.name : ''} for signing.`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$http.post(`purchase/purchase-request/${this.$route.params.id}/purchase-order/${this.vendor._id}/requester/submit`, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.$swal({
                  title: 'Submitted for Approval',
                  html: `${this.vendor.stringID} will now be sent to the next assigned signatory (${this.vendor.signatories[1] ? this.vendor.signatories[1].user.name : ''}) for signing.`,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(confirm => {
                    if (confirm.value) {
                      this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: this.$route.params.id } })
                    }
                  })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    sendBackToRequester(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.sendBackToRequesterForm.validate().then(success => {
        if (success) {
          this.$refs['modal-send-back'].toggle('#toggle-btn')
          const formData = new FormData()
          formData.append('comment', this.comment)
          formData.append('stage', 'in-request')

          this.$http.post(`purchase/purchase-request/${this.$route.params.id}/send-back`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.$swal({
                title: 'PO updated to Pending Edit',
                html: `The requester (${this.vendor.createdBy ? this.vendor.createdBy.name : ''}) has been informed of your comments. You may verify this PO after the requester re-submits this PO.`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/success.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
                .then(confirm => {
                  if (confirm.value) {
                    this.$router.push({ name: 'purchasing-purchase-request-show', params: { id: this.$route.params.id } })
                  }
                })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'comment') {
                    this.commentError = validationError.msg
                    this.commentValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },

    approveVendor() {
      let index = this.signIndex
      // eslint-disable-next-line radix
      index = parseInt(index)
      const id = this.userID
      let firstSwalMessage = ''
      let secondSwalMessage = ''
      let secondSwalTitle = 'Vendor Registration Form Signed'
      if ((this.vendor.signatories.length - 1) === index) {
        firstSwalMessage = `You are proceeding to sign this Vendor Registration Form. Following this, ${this.vendor.companyName} will be added to the approved vendor list.`
        secondSwalTitle = 'Vendor Approved'
        secondSwalMessage = `${this.vendor.companyName} has been added to Approved list.<br>All relevant parties will receive a notification.`
      } else {
        firstSwalMessage = `You are proceeding to sign on this Vendor Registration Form. The form will then be sent to ${this.vendor.signatories[index + 1] ? this.vendor.signatories[index + 1].user.name : ''} for signing.`
        secondSwalMessage = `${this.vendor.companyName} registration form will now be sent to the next assigned signatory (${this.vendor.signatories[index + 1] ? this.vendor.signatories[index + 1].user.name : ''}) for signing.`
      }
      // this.previewPage = true
      this.$swal({
        title: 'Sign this Vendor Registration Form?',
        html: firstSwalMessage,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Proceed!',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            formData.append('url', window.location.origin)
            formData.append('id', id)
            formData.append('index', index)
            formData.append('signatoryRequired', this.vendor.signatories.length)

            this.$http.post(`purchase/vendors/${this.$route.params.id}/approve`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.$swal({
                  title: secondSwalTitle,
                  html: secondSwalMessage,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(confirm => {
                    if (confirm.value) {
                      if ((this.vendor.signatories.length - 1) === index) {
                        this.$router.push({ name: 'purchasing-vendors-show', params: { id: this.$route.params.id } })
                      } else {
                        this.$router.push({ name: 'purchasing-vendors-index', params: { type: 'all-vendors' } })
                      }
                    }
                  })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    setData() {
      this.vendorID = this.vendor._id
      this.status = this.vendor.status
      this.stringID = this.vendor.stringID

      this.$http.get(`directory/users/${this.vendor.createdBy._id}/show`)
        .then(response => {
          this.user = response.data ?? {}
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    submitForm() {
      this.$refs.vendorStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`purchase/purchase-request/${this.vendorID}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.vendor.status = response.data.data.status || 'confirmed'
              this.setData()
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>${this.stringID}</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
